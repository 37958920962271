<template lang="pug">
    .connection-view.pa-5
      h3 Redirecting to {{cloudCompanyName}} in order to connect to {{cloudAppName}}...
</template>

<script>
export default {
  name: "ConnectionsPage",
  data() {
    return {
      cloudCompanyName:
        this.$route.query.cloudCompanyName || "your cloud accounting tool",
      cloudAppName: this.$route.query.cloudAppName || "your data"
    };
  }
};
</script>
