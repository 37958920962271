<template lang="pug">
    .connection-callback.pa-5
        v-card.pa-5(flat)
            h1 Please wait a moment...
        v-overlay(:value="true")
          v-progress-circular(indeterminate size="64")
</template>

<script>
export default {
  name: "ConnectionCallback",
  created() {
    this.init();
  },
  methods: {
    init() {
      try {
        if (!window || !window.opener)
          console.error("connection modal has no opener!");
        else {
          window.opener.postMessage(
            JSON.parse(
              '{"' +
                decodeURI(location.search.substring(1))
                  .replace(/"/g, '\\"')
                  .replace(/&/g, '","')
                  .replace(/=/g, '":"') +
                '"}'
            ),
            window.origin
          );
        }
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>
